<template>
  <div
    :class="{
      'bottom-0 h-8 w-full lg:h-64 lg:w-96 lg:left-10': minimized,
      'top-0 w-full h-screen bg-overlay': !minimized,
    }"
    class="fixed flex items-center justify-center left-0 z-30"
  >
    <div
      id="call-window"
      :class="{
        'h-full minimized': minimized,
        'h-screen not-minimized bg-blackBg': !minimized,
      }"
      class="video-window relative lg:rounded-xl w-full md:h-full mx-auto"
    >
      <div
        v-show="minimized"
        class="minimize absolute -top-10 left-2 lg:-left-6 lg:-top-6 z-50"
      >
        <menuButton
          @on-click="minimize"
          class="mr-4"
          :icon="minimized ? 'maximizeIcon' : 'minimizeIcon'"
          mobile
        />
        <menuButton
          v-show="minimized"
          @on-click="toggleHideVideo"
          class="mr-4 mt-2"
          :icon="videoHidden ? 'eye' : 'eyeOff'"
          mobile
        />
      </div>
      <div
        v-if="isScanning"
        class="barcode-scanning-mode rounded-xl absolute top-0 left-0 w-full h-full flex items-center justify-center z-1 bg-lightYellow "
      >
        <div class="relative">
          <div
            id="red-line"
            class="absolute w-full top-10 left-0 h-2 bg-red"
          ></div>
          <img
            :class="{ 'w-80': !minimized, 'w-40': minimized }"
            src="@/assets/barcode.png"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="!minimized"
        class="absolute top-2 -right-2 lg:-top-4 lg:-right-8 z-50"
      >
        <div class="reative">
          <menuButton
            @on-click="showCart"
            class="mr-4"
            :status="getCartItemsNumber"
            icon="cart"
            mobile
          />
        </div>
      </div>
      <div
        v-if="operator && !minimized"
        class="absolute p-2 mt-16 text-extrasm cursor-pointer inline-block rounded-md text-white m-8 z-50"
      >
        Konsultantas: {{ operator.name }}
      </div>
      <!-- v-draggable="draggableWithResetPosition" -->
      <video
        v-show="!isScanning && !videoHidden"
        id="operator-video-stream"
        :class="{
          'fixed bottom-8 left-4 w-40': minimized,
          'relative h-full top-0': !minimized,
        }"
        class="left-0 rounded-xl"
        width="100%"
        height="636px"
        playsinline
        autoplay
      ></video>
      <div
        v-show="showActionIcons"
        :class="{ hidden: minimized || iconsHidden }"
        class="action-icons flex absolute right-8 bottom-20 md:bottom-8"
      >
        <actionIcon
          :key="action"
          :icon="action.icon"
          :tooltip="action.tooltip"
          @on-click="initAction(action.action)"
          :color="action.color"
          v-for="action of actions"
        />
      </div>
      <div
        v-show="cameraOn && !isScanning"
        :class="{ hidden: minimized }"
        class="shadow-xl w-32 lg:w-48 absolute top-32 lg:bottom-10 left-4 lg:left-10 rounded-md"
      >
        <video
          id="my-stream-video"
          class="rounded-md"
          autoplay
          muted
          playsinline
        ></video>
      </div>
    </div>
    <div
      v-if="!minimized"
      @click="$emit('close')"
      v-html="closeIcon"
      class="absolute right-6 top-6 cursor-pointer"
    ></div>
    <slideout-panel></slideout-panel>
  </div>
</template>

<script>
import Vue from "vue";
// import { Draggable } from "draggable-vue-directive";
import VueSlideoutPanel from "vue2-slideout-panel";
import { db } from "../fire.js";
import menuButton from "../../smallComponents/menuButton";
import actionIcon from "./smallComponents/actionIcon";
import { mapState } from "vuex";
Vue.use(VueSlideoutPanel);

export default {
  // directives: {
  //   Draggable,
  // },
  components: {
    actionIcon,
    menuButton,
  },
  data() {
    return {
      videoHidden: false,
      // draggableWithResetPosition: {
      //   resetInitialPos: false,
      //   onDragEnd: this.onDragEnd,
      // },
      showShoppingCart: false,
      showActionIcons: true,
      elmnt: null,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      cameraOff:
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video-off"><path d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>',
      isScanning: false,
      soundEnabled: true,
      cameraOn: true,
      closeIcon:
        '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
    };
  },
  computed: {
    actions() {
      return [
        // {
        //   icon: "chat",
        //   action: "openChat",
        //   tooltip: "Rašyti žinutę",
        //   color: "green",
        // },
        {
          icon: this.cameraOn ? "camera" : "camera",
          action: "toggleCamera",
          tooltip: "Išjungti/įjungti kamerą",
          color: this.cameraOn ? "grays" : "red",
        },
        {
          icon: this.soundEnabled ? "microphone" : "micOff",
          action: "mute",
          tooltip: "Išjungti/įjungti garsą",
          color: this.soundEnabled ? "grays" : "red",
        },
        {
          icon: "disconnect",
          tooltip: "Baigti pokalbį",
          color: "red",
          action: "disconnect",
          size: "bigger",
        },
      ];
    },
    ...mapState({
      user: (state) => state.user,
      userID: (state) => state.userID,
      minimized: (state) => state.minimized,
      operator: (state) => state.operator,
      cartContent: (state) => state.cartContent,
      cartItemsCount: (state) => state.cartItemsCount,
      currentCallKey: (state) => state.currentCallKey,
    }),
    getCartItemsNumber() {
      if (!this.cartContent) return;
      let total = 0;
      for (let itemKey in this.cartContent) {
        total += this.cartContent[itemKey].qty;
      }
      return total;
    },
  },
  mounted() {
    this.initStatusWaiting();
    this.initIdleStatus();
    setTimeout(() => {
      this.initStreamOn();
    }, 1000);
  },
  methods: {
    toggleHideVideo() {
      this.videoHidden = !this.videoHidden;
    },

    onDragEnd() {
      if (!this.minimized) {
        this.onReset();
      }
    },

    initStreamOn() {
      console.log(
        `currentCalls/${this.userID}/${this.operator.id}/${this.currentCallKey}/userStreamOn`,
        "`currentCalls/${this.userID}/${this.operator.id}/${this.currentCallKey}/userStreamOn`"
      );
      db.ref(
        `currentCalls/${this.userID}/${this.operator.id}/${this.currentCallKey}/userStreamOn`
      ).set(true);
    },

    onReset() {
      this.draggableWithResetPosition.resetInitialPos = true;
      setTimeout(() => {
        this.draggableWithResetPosition.resetInitialPos = false;
      }, 10);
    },

    toggleMinimized() {
      if (this.minimized) {
        this.$store.commit("toggleMinimized");
        this.onReset();
      }
      this.showActionIcons = !this.showActionIcons;
    },

    minimize() {
      this.$store.commit("toggleMinimized");
    },

    showCart() {
      this.minimize();
      this.$store.commit("toggleSlideOut");
    },

    initIdleStatus() {
      this.$store.commit(
        "setIdleStatusInterval",
        setInterval(() => {
          db.ref(
            `currentCalls/${this.userID}/${this.operator.id}/${this.currentCallKey}/activeTime`
          ).set(Date.now());
        }, 5000)
      );
    },

    initStatusWaiting() {
      const self = this;
      db.ref(`userCart/${this.userID}/status`).on("value", function(snapshot) {
        if (!snapshot.val()) return;
        if (snapshot.val().scanning) {
          self.isScanning = true;
        } else {
          self.isScanning = false;
        }
      });
    },

    initAction(action) {
      this[action]();
    },

    disconnect() {
      if (!this.cartItemsCount) {
        this.$emit("close");
        return;
      }

      db.ref(`userCart/${this.userID}/finished`).update({
        time: JSON.stringify(Date.now()),
        operator: this.operator.id,
      });

      this.$root.notify("Krepšelis buvo sėkmingai suformuotas", "success");
      this.$emit("close");
    },

    toggleCamera() {
      this.cameraOn = this.$root.peer.streams[0].getTracks()[1].enabled = !this
        .cameraOn;
      db.ref(
        `currentCalls/${this.userID}/${this.operator.id}/${this.currentCallKey}/userStreamOn`
      ).set(this.cameraOn);
    },

    mute() {
      this.soundEnabled = this.$root.peer.streams[0].getAudioTracks()[0].enabled = !this
        .soundEnabled;
    },

    showAddedItem() {
      this.showShoppingCart = true;
      setTimeout(() => {
        this.showShoppingCart = false;
      }, 2000);
    },
  },
  watch: {
    cartContent() {
      this.$root.notify("Krepšelis atnaujintas");
    },
  },
};
</script>

<style lang="scss">
#call-window {
  width: 1100px;
  height: 90%;
}
#videoElement {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
#red-line {
  will-change: transform;
  transform: translateY(0px);
  transition: all 5.5s linear;
  animation-delay: 3s;
  animation: upAndDown 6.5s infinite ease-in-out;
}
@keyframes upAndDown {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 100px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@media only screen and (max-width: 900px) {
  .not-minimized {
    &.video-window {
      height: 100vh !important;
      z-index: 1000;
      overflow: scroll;
    }
  }
  .minimized {
    &.video-window {
      height: 20vh !important;
      z-index: 1000;
    }
  }
}
</style>
