<template>
  <div
    :id="notpopup ? 'no-popup' : null"
    :class="{
      'right-0 w-96': left,
      'ml-4 mr-4 relative': notpopup,
    }"
    class="p-4 pb-8 pl-8 pr-8 mt-5 z-50 rounded-2xl"
  >
    <div v-if="cartItemsCount">
      <div
        v-if="!notpopup"
        :class="{
          'left-16': !left,
          'right-6': left,
          relative: notpopup,
          absolute: !notpopup,
        }"
        class="arrow-up -top-4 opacity-9 z-0"
      ></div>
      <div class="flex justify-between">
        <div class="mb-4 text-header font-bold">
          Krepšelis
        </div>
        <div
          @click="closeCart"
          v-html="closeIcon"
          class="cursor-pointer lg:hidden"
        ></div>
      </div>
      <div :class="{ 'max-h-full': notpopup }" id="scroll-cart">
        <div
          @click="toggleOptions(item.id)"
          :key="item"
          v-for="(item, key) in cartContent"
          class="bg-white relative overflow-x-hidden shadow-md rounded p-4 mb-4 text-extrasm flex justify-between"
        >
          <div
            :class="{
              'translate-x-0': showSlideOptions == item.id,
              'translate-x-full': showSlideOptions != item.id,
            }"
            :style="{
              'box-shadow':
                showSlideOptions == item.id
                  ? '-10px 0px 10px 1px rgba(122,122,122,0.2)'
                  : 'none',
            }"
            class="absolute transform transition ease-in-out duration-500 sm:duration-700 flex justify-center items-center right-0 top-0 h-full w-48 text-right pr-0 bg-white"
          >
            <button
              v-if="item.qty > 1"
              @click="minusOne(item, key)"
              type="button"
              v-html="minusIcon"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-grays hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red"
            ></button>

            <!-- <button
              @click="plusOne(item, key)"
              type="button"
              v-html="plusIcon"
              class="inline-flex ml-2 mr-2 items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-grays hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            ></button> -->

            <button
              @click="remove(item, key)"
              type="button"
              class="inline-flex ml-2 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red"
            >
              Pašalinti
              <div v-html="trashIcon" class="ml-2"></div>
            </button>
          </div>
          <div class="w-4/5">
            {{ item.title }}
            <div class="font-bold">
              {{ (item.price * item.qty).toFixed(2) }}
              {{ currency }}
            </div>
          </div>
          <div>× {{ item.qty }}</div>
        </div>
      </div>
      <div id="total" class="bg-white border border-yellow p-4 rounded text-sm">
        <div v-if="appliedDiscount">Nuolaida: {{ appliedDiscount }}%</div>
        <div class="font-normal text-extrasm">
          {{ (totalWithDiscount / 1.21).toFixed(2) }} {{ currency }} + PVM
          {{ (totalWithDiscount - totalWithDiscount / 1.21).toFixed(2) }}
          {{ currency }}
        </div>
        <div class="font-bold">
          Viso:
          {{ totalWithDiscount }} {{ currency }}
        </div>
      </div>
      <mainBtn
        @on-click="gotoCheckout"
        class="mt-4"
        text="Apmokėti"
        bold
        icon="arrow"
        iconPos="right"
        textColor="green"
        bgColor="yellow"
      />
    </div>
    <div v-else class="w-full text-center mt-8 text-lg">
      <div
        @click="closeCart"
        v-html="closeIcon"
        class="cursor-pointer lg:hidden absolute right-2 top-2"
      ></div>
      Krepšelis yra tuščias
    </div>
  </div>
</template>

<script>
import { db } from "./videoMessenger/fire.js";
import mainBtn from "./smallComponents/mainBtn";
import PerfectScrollbar from "perfect-scrollbar";
import { mapState } from "vuex";
const axios = require("axios");

export default {
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    notpopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    mainBtn,
  },
  data() {
    return {
      // cartContent: [
      //   {
      //     created_at: "2021-04-13 08:48:19",
      //     ean: "22677115481",
      //     id: 7912,
      //     price: 12.9,
      //     product_id: "XR10P",
      //     qty: 1,
      //     title: "Vobleris RAPALA XR-10 P",
      //     updated_at: "2021-04-23 17:00:59",
      //     vat_percent: "-17.36",
      //   },
      //   {
      //     created_at: "2021-04-13 08:48:19",
      //     ean: "22677115481",
      //     id: 7912,
      //     price: 12.9,
      //     product_id: "XR10P",
      //     qty: 1,
      //     title: "Vobleris RAPALA XR-10 P",
      //     updated_at: "2021-04-23 17:00:59",
      //     vat_percent: "-17.36",
      //   },
      //   {
      //     created_at: "2021-04-13 08:48:19",
      //     ean: "22677115481",
      //     id: 7912,
      //     price: 12.9,
      //     product_id: "XR10P",
      //     qty: 1,
      //     title: "Vobleris RAPALA XR-10 P",
      //     updated_at: "2021-04-23 17:00:59",
      //     vat_percent: "-17.36",
      //   },
      // ],
      minusIcon:
        '<svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" /> </svg>',
      plusIcon:
        '<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" > <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" /> </svg>',
      trashIcon:
        '<svg width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>',
      showSlideOptions: null,
      scrollContainer: null,
      cartActive: false,
      currency: "€",
      closeIcon:
        '<svg width="32" height="32" viewBox="0 0 26 26" fill="none"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
    };
  },
  mounted() {
    setTimeout(() => {
      this.initCart();
      this.getDiscounts();
    }, 1500);
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      discounts: (state) => state.discounts,
      userID: (state) => state.userID,
      cartActive: (state) => state.cartActive,
      tempUserID: (state) => state.tempUserID,
      appliedDiscount: (state) => state.appliedDiscount,
      cartContent: (state) => state.cartContent,
      cartItemsCount: (state) => state.cartItemsCount,
    }),
    cartIsEmpty() {
      if (!this.cartContent) return true;
      return false;
    },
    total() {
      let price = 0;
      for (let itemKey in this.cartContent) {
        price +=
          this.cartContent[itemKey].price * this.cartContent[itemKey].qty;
      }
      return price;
    },
    totalWithDiscount() {
      return (this.total - (this.total * this.appliedDiscount) / 100).toFixed(
        2
      );
    },
  },
  methods: {
    getDiscountSize(discount) {
      const dicountObj = this.discounts.find(
        (element) => element.code == discount
      );
      if (dicountObj) {
        return dicountObj.percentage;
      } else {
        return null;
      }
    },
    plusOne(item, key) {
      db.ref(`userCart/${this.userID}/items/${key}`).update({
        qty: item.qty + 1,
      });
      item.qty++;
    },
    minusOne(item, key) {
      db.ref(`userCart/${this.userID}/items/${key}`).update({
        qty: item.qty - 1,
      });
      item.qty--;
      db.ref(`userCart/${this.userID}/qty_change`).push({
        title: item.title,
        qty: item.qty,
      });
    },
    remove(item, key) {
      this.$notify.closeAll();
      this.$notify.error({
        group: "foo",
        message: `Ar tikrai norite pašalinti prekę "${item.title}"?`,
        showClose: true,
        multiLine: false,
        closeDelay: 5000,
        hideIcon: false,
        offset: 0,
        fullWidth: false,
        actionText: "TAIP",
        onActionClick: () => this.finalRemove(key, item),
      });
    },
    finalRemove(key, item) {
      this.$notify.closeAll();
      db.ref(`userCart/${this.userID}/removed`).push({
        title: item.title,
        qty: item.qty,
      });
      db.ref(`userCart/${this.userID}/items/${key}`).remove();
      if (this.cartIsEmpty) {
        db.ref(`userCart/${this.userID}`).remove();
        this.$emit("close");
      }
      setTimeout(() => {
        this.$root.notify("Pirkinys buvo pašalintas");
      }, 500);
    },
    toggleOptions(id) {
      this.showSlideOptions = this.showSlideOptions == id ? null : id;
    },
    closeCart() {
      this.$store.commit("toggleSlideOut");
    },
    gotoCheckout() {
      if (this.$route.name !== "atsiskaityti") {
        this.$router.push("/atsiskaityti").catch(() => {});
      } else {
        this.closeCart();
      }
    },
    async getDiscounts() {
      const self = this;
      const result = await axios.get(`${this.$root.baseUrl}/get-discounts`);
      this.$store.commit("setDiscounts", result.data.discounts);
      db.ref(`userCart/${this.userID}/discount`).on("value", function(
        snapshot
      ) {
        if (!snapshot.val()) {
          self.$store.commit("setDiscountSize", 0);
          return;
        }
        self.discount = snapshot.val().code;
        self.applyDiscount();
      });
    },
    applyDiscount() {
      const discountSize = this.discounts.find(
        (element) => element.code == this.discount
      );
      if (discountSize) {
        this.$store.commit("setDiscountSize", discountSize.percentage);
      } else {
        this.$store.commit("setDiscountSize", 0);
      }
    },
    initScroll() {
      this.scrollContainer = new PerfectScrollbar("#scroll-cart", {
        wheelPropagation: false,
      });
      this.scrollContainer = new PerfectScrollbar("#scroll-cart2", {
        wheelPropagation: false,
      });
    },
    priceWithoutVAT(item) {
      // const discountSize = this.getDiscountSize(item.discount);
      const priceBeforeVat = item.price / 1.21;
      // const priceAfterDiscount =
      //   priceBeforeVat - (priceBeforeVat * discountSize) / 100;
      return (priceBeforeVat * item.qty).toFixed(2);
    },
    initCart() {
      if (!this.userID) this.$store.commit("setUserID", this.tempUserID);
      const self = this;
      db.ref(`userCart/${this.userID}/items/`).on("value", function(snapshot) {
        self.$emit("show-added-item");
        self.$store.commit("setCartItemsCount", snapshot.numChildren());
        self.$store.commit("updateCartContent", snapshot.val());
      });
    },
  },
  watch: {
    user() {
      this.initCart();
    },
    cartItemsCount() {
      // this.initScroll();
    },
  },
};
</script>

<style lang="scss">
.arrow-up {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
}
// #scroll-cart {
//   max-height: 320px;
//   overflow: hidden;
// }
// #no-popup {
//   #scroll-cart {
//     max-height: 100%;
//     overflow: hidden;
//   }
// }
</style>
