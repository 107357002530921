<template>
  <div class="mt-2 transition select-none" style="max-width:300px">
    <label class="w-full text-extrasm select-none"
      >{{ item.label }}
      <span v-if="isRequired" class="text-red">*</span>
    </label>
    <validation-provider
      :rules="rulesObject"
      :name="item.confirm ? 'confirm' : null"
      :vid="item.confirm ? 'confirm' : null"
      v-slot="{ errors }"
    >
      <input
        :id="id"
        :type="type"
        :name="id"
        v-model="item.value"
        :maxlength="item.maxlength"
        :placeholder="placeholder"
        class="p-2 pl-3 text-black outline-none focus:ring-1 transition focus:ring-yellow border-yellow bg-lightYellow border rounded-lg w-full"
      />
      <span class="text-extrasm text-red">{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";
import Cleave from "cleave.js";

extend("email", {
  ...email,
  message: "neteisingas el. pašto adresas",
});
extend("regex", {
  ...regex,
  message: "Neteisingas telefono numeris",
});
extend("required", {
  ...required,
  message: "būtina užpildyti",
});

export default {
  props: {
    id: {
      type: String,
    },
    item: {
      type: Object,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isEmail: {
      type: Boolean,
      default: false,
    },
    isZipCode: {
      type: Boolean,
      default: false,
    },
    isPhone: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      rulesObject: {
        required: this.isRequired,
        email: this.isEmail,
      },
    };
  },
  components: {
    ValidationProvider,
  },
  mounted() {
    this.initCleave();
  },
  methods: {
    initCleave() {
      if (this.isPhone) {
        new Cleave("#phone", {
          phone: true,
          noImmediatePrefix: true,
          phoneRegionCode: "LT",
          prefix: "+370",
        });
      } else if (this.isZipCode) {
        new Cleave("#zip", {
          numeral: true,
          delimiter: true,
          noImmediatePrefix: false,
          numeralThousandsGroupStyle: "none",
          stripLeadingZeroes: false,
          prefix: "LT ",
        });
      }
    },
  },
};
</script>

<style></style>
