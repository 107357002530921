<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="fixed inset-0 overflow-hidden z-50 w-screen h-screen max-h-screen"
    aria-labelledby="slide-over-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="absolute ease-in-out duration-500 opacity-100 inset-0 overflow-hidden h-screen"
    >
      <!--
      Background overlay, show/hide based on slide-over state.

      Entering: "ease-in-out duration-500"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in-out duration-500"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        class="absolute inset-0 opacity-100 bg-gray-500 bg-opacity-75 transition-opacity h-screen overflow-hidden"
        aria-hidden="true"
      ></div>

      <div
        class="fixed inset-y-0 translate-x-0 right-0 pl-10 max-w-full flex h-screen"
      >
        <!--
        Slide-over panel, show/hide based on slide-over state.

        Entering: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-full"
          To: "translate-x-0"
        Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-0"
          To: "translate-x-full"
      -->
        <div class="relative opacity-100 w-screen max-w-md">
          <!--
          Close button, show/hide based on slide-over state.

          Entering: "ease-in-out duration-500"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-500"
            From: "opacity-100"
            To: "opacity-0"
        -->
          <div
            class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
          >
            <button
              @click="close"
              class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span class="sr-only">Close panel</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div
            class="h-full flex flex-col pt-4 bg-white shadow-xl overflow-y-scroll"
          >
            <div class="px-4 sm:px-6">
              <h2 class="text-lg font-bold text-gray-900" id="slide-over-title">
                {{ titles[showPrivacyOrReturns] }}
              </h2>
            </div>
            <div
              class="mt-6 pb-40 relative flex-1 px-4 sm:px-6 overflow-y-scroll h-screen"
            >
              <privacy v-if="showPrivacyOrReturns == 'privacy'" />

              <div class="pl-4" v-else>
                <ul class="list-disc">
                  <li
                    :key="item"
                    v-for="item of texts['returns']"
                    v-html="item.text"
                  ></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import privacy from "./privacy";

export default {
  components: {
    privacy,
  },
  data() {
    return {
      titles: {
        return: "Prekių pristatymo ir grąžinimo tvarka",
        privacy: "Privatumo politika",
      },
      texts: {
        returns: [
          {
            text:
              "<b>Prekių atsiėmimas parduotuvėje:</b><br/> - Prekes parduotuvėje adresu Šeimyniškių 21 Vilniuje galite atsiimti nemokamai kasdien nuo 9 iki 22 valandos.",
          },
          {
            text:
              "<b>Pristatymas su DPD kurjeriu</b><br/> - Pirkdami mūsų parduotuvėje, 99% atvejų prekes gausite per 1-2 darbo dienas. Jei prekes užsakysite iki tos pačios dienos 12 valandos, prekes gausite jau kitą darbo dieną, jei po 12-os valandos – siuntinys bus atgabentas dar kitą darbo dieną. Pristatymo laikas priklauso nuo Lietuvos regiono, tad kai kurių miestų ir vietovių gyventojai pirkdami gali pasirinkti tikslesnį pristatymo laiką.",
          },
          {
            text:
              "<b>Greitasis pristatymas Vilniuje su ZITYCITY</b><br/> - Kurjeriai prekes pristato tą pačią dieną nuo 14 iki 22 valandos Vilniuje, prekes užsakius iki tos pačios dienos 12 valandos. Paslauga teikiama darbo dienomis ir šeštadieniais. Klientai apie savo užsakymo pristatymą informuojami trumposiomis SMS žinutėmis, o, pasinaudoję gauta nuoroda, gali stebėti prekių kelionę žemėlapyje realiu laiku.",
          },
          {
            text:
              "<b>Pardavėjo atsakomybė</b><br/> - Pardavėjas neatsako už Prekių pateikimo terminų pažeidimą, jeigu Prekės Pirkėjui nėra pateikiamos arba pateikiamos ne laiku dėl trečiųjų asmenų, nesusijusių su Pardavėju, kaltės arba dėl nuo Pirkėjo priklausančių aplinkybių. <br/> - Pristačius ir perdavus Prekes Pirkėjo nurodytu adresu, laikoma, kad Prekės Pirkėjui yra perduotos, neatsižvelgiant į tai, ar Prekes faktiškai priima Pirkėjas, ar bet kuris kitas asmuo, priėmęs Prekes nurodytu adresu.",
          },
          {
            text:
              'Klientui parduotų prekių trūkumai šalinami, nekokybiškos prekės keičiamos ir grąžinamos remiantis „Daiktų grąžinimo ir keitimo taisyklėmis, patvirtintomis LR ūkio ministro 2001 m. birželio 29 d. įsakymu Nr. 217 „Dėl daiktų grąžinimo ir keitimo taisyklių patvirtinimo".',
          },
          {
            text:
              "Nepatikusių prekių grąžinimo atveju, pristatymo mokestį turi mokėti klientas. Grąžinamų nekokybiškų prekių siuntinio pristatymo išlaidas padengia pardavėjas.",
          },
          {
            text:
              "Pardavėjas prekės iš kliento priima, jeigu: prekė nenaudota, nepraradusi savo prekinės išvaizdos bei nėra sugadinta kliento, pateikiama originalioje tvarkingoje pakuotėje, grąžinama pilna prekės komplektacija, klientas pateikia prekės įsigijimo dokumentą.",
          },
          {
            text:
              "Prekė nekeičiama, neatlygintinai trūkumai nešalinami ir pinigai negrąžinami, jei prekės trūkumų atsirado dėl to, kad klientas pažeidė prekės naudojimo ar saugojimo taisykles.",
          },
          {
            text:
              "Norėdamas grąžinti prekę(-es) remiantis taisyklių prekių grąžinimo pirmu punktu, pirkėjas gali tai padaryti per 14 (keturiolika) kalendorinių dienų nuo prekių pristatymo pirkėjui dienos, informuodamas pardavėją elektroniniu laišku (parduotuve@kotas.lt ir apskaita@kotas.lt), nurodant grąžinamos prekės pavadinimą, užsakymo numerį, grąžinimo priežastis, banko sąskaitos kur pervesti pinigus numerį ir priede prisegant PVM sąskaitą faktūrą kuri buvo gauta el. paštu įsigijimo metu.",
          },
        ],
      },
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      showPrivacyOrReturns: (state) => state.showPrivacyOrReturns,
    }),
  },
  methods: {
    close() {
      this.$store.commit("triggerShowPrivacyOrReturns", false);
    },
  },
};
</script>

<style lang="scss">
ul {
  li {
    @apply mb-4;
  }
}
</style>
