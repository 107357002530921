import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    testMode: false,
    user: null,
    userID: null,
    tempUserID: null,
    token: null,
    purchases: null,
    minimized: false,
    showRegModal: false,
    showLoginModal: false,
    slideOutOpen: false,
    showPrivacyOrReturns: false,
    discounts: null,
    userInfo: {
      name: {
        id: "name",
        label: "Vardas, Pavardė",
        value: "",
        required: true,
        type: "textfield",
      },
      email: {
        id: "email",
        label: "El. paštas",
        value: "",
        required: true,
        isEmail: true,
        type: "textfield",
      },
      phone: {
        id: "phone",
        label: "Telefonas",
        value: "",
        required: true,
        isPhone: true,
        placeholder: "+370 600 12345",
        type: "textfield",
        disable: true,
      },
      address: {
        id: "address",
        label: "Pilnas adresas",
        value: "",
        required: true,
        type: "textfield",
        disable: true,
      },
      city: {
        id: "city",
        label: "Miestas",
        value: "",
        required: true,
        type: "textfield",
        disable: true,
      },
      zip: {
        id: "zip",
        label: "Pašto indeksas",
        value: "",
        required: true,
        type: "textfield",
        disable: true,
        isZipCode: true,
        maxlength: 8
      },
    },
    videoCallWindowShow: false,
    operator: null,
    showTour: false,
    slideOutToggle: false,
    cartActive: false,
    showMessenger: false,
    userIsBlocked: false,
    waitingForOperator: false,
    messengerOpen: false,
    cartContent: [],
    idleStatusInterval: null,
    currentCallAnswerKey: null,
    cartItemsCount: null,
    currentStream: null,
    appliedDiscount: null,
    shippingPrice: 0,
    currentCallKey: null,
},
  mutations: {
      toggleShowTour(state){
        state.showTour = !state.showTour
      },
      setUserBlocked(state, status){
        state.userIsBlocked = status
      },
      triggerShowPrivacyOrReturns(state, status) {
        state.showPrivacyOrReturns = status
      },
      setCurrentCallAnswerKey(state, key){
        state.currentCallAnswerKey = key
      },
      setIdleStatusInterval(state, func){
        state.idleStatusInterval = func
      },
      toggleMinimized(state){
        state.minimized = !state.minimized
      },
      setShippingPrice(state, value){
        state.shippingPrice = value
      },
      setUser(state, user){
        state.user = user
        state.userID = user.id ? user.id : null;
      },
      setCartActive(state, status){
        state.cartActive = status
      },
      setVideoCallWindowShow(state, status){
        state.videoCallWindowShow = status
      },
      setUserToken(state, token){
        state.token = token
      },
      setWaitingForOperator(state, status){
        state.waitingForOperator = status
      },
      setUserID(state, userID){
        state.userID = userID
      },
      setTempUserID(state, id) {
        state.tempUserID = id
      },
      toggleShowRegModal(state, status){
        state.showRegModal = status
      },
      toggleShowLoginModal(state, status) {
        state.showLoginModal = status
      },
      setCurrOperator(state, operator) {
        state.operator = operator
      },
      updateCartContent(state, content){
          state.cartContent = content
      },
      setCurrentStream(state, stream){
        state.currentStream = stream
      },
      setSlideOutOpen(state, status){
        state.slideOutOpen = status
      }, 
      toggleSlideOut(state){
        state.slideOutToggle = !state.slideOutToggle 
      }, 
      updatePurchases(state, purchases) {
        state.purchases = purchases
      },
      setCartItemsCount(state, count){
        state.cartItemsCount = count
      },
      setDiscounts(state, discounts){
        state.discounts = discounts
      },
      setDiscountSize(state, content) {
        state.appliedDiscount = content
      },
      triggerTestMode(state){
        state.testMode = !state.testMode
      },
      setShowMessenger(state, status){
        state.showMessenger = status
      },
      closeMessenger(state){
        state.messengerOpen = false
      },
      openMessenger(state){
        state.messengerOpen = !state.messengerOpen;
      },
      setCurrentCallKey(state, key){
        state.currentCallKey = key
      }
  },
  getters: {
  }
});

export default store;
