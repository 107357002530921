<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="py-16 overflow-hidden lg:py-24 w-10/12 mx-auto">
        <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <svg
                class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                    >
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div class="relative">
                    <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                        Kaip tai veikia?
                    </h3>
                    <p class="mt-3 text-md text-gray-500">
                        Video skambučio metu mūsų patyrę žūklės konsultantai patars ir suformuos jūsų pirkinių krepšelį.
                        <br />
                    </p>

                    <dl class="mt-10 space-y-10">
                        <div class="relative">
                            <dt>
                                <div
                                    class="absolute font-bold flex items-center justify-center h-12 w-12 rounded-md bg-green text-white"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        width="22"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                        />
                                    </svg>
                                </div>
                                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                                    Inicijuokite video apsipirkimą
                                </p>
                            </dt>
                            <dd class="mt-2 ml-16 text-base text-gray-500">
                                Pasirinkus "pradėti video apsipirkimą", naršyklė paprašys suteikti Audio ir Video
                                leidimus, kad video skambučio metu galėtumėte sklandžiai bendrauti su konsultantu. Pagal
                                nutylėjimą, bendravimo metu jūsų kamera bus įjungta.
                            </dd>
                        </div>

                        <div class="relative">
                            <dt>
                                <div
                                    class="absolute font-bold flex items-center justify-center h-12 w-12 rounded-md bg-green text-white"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        width="22"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                        />
                                    </svg>
                                </div>
                                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                                    Konsultantas kartu su jumis suformuos prekių krepšelį
                                </p>
                            </dt>
                            <dd class="mt-2 ml-16 text-base text-gray-500">
                                Konsultantui skenuojant jūsų norimas prekes, jos nedelsiant atsiras jūsų pirkinių
                                krepšelyje. Jums nereikės nei vieno pelytės ar ekrano paspaudimo.
                            </dd>
                        </div>

                        <div class="relative">
                            <dt>
                                <div
                                    class="absolute font-bold flex items-center justify-center h-12 w-12 rounded-md bg-green text-white"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        width="22"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                        />
                                    </svg>
                                </div>
                                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                                    Pasirinkite apmokėjimo ir pristatymo būdą
                                </p>
                            </dt>
                            <dd class="mt-2 ml-16 text-base text-gray-500">
                                Baigus video apsipirkimą, pasirinkite jums tinkamą apmokėjimo ir pristatymo būdą. <br />
                                <b>Naujiena:</b> Vilniaus mieste prekės pristatomos per 2 valandas!
                            </dd>
                        </div>
                    </dl>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                    <svg
                        class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                        width="784"
                        height="404"
                        fill="none"
                        viewBox="0 0 784 404"
                    >
                        <defs>
                            <pattern
                                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                    </svg>
                    <img
                        class="relative mx-auto rounded-2xl shadow-xl"
                        width="390"
                        src="@/assets/promo_cart.png"
                        alt=""
                    />
                </div>
            </div>
            <div class="mb-4 text-base font-semibold">Palaikomos naršyklės:</div>
            <div class="text-base">
                <div class="flex">
                    Mac:
                    <img src="@/assets/browsers/chrome.svg" alt="chrome" class="w-6 ml-2" />
                    <img src="@/assets/browsers/firefox.svg" alt="firefox" class="w-6 ml-2" />
                    <img src="@/assets/browsers/safari-ios.svg" alt="safari" class="w-6 ml-2" />
                </div>
                <div class="flex mt-2">
                    PC:
                    <img src="@/assets/browsers/chrome.svg" alt="chrome" class="w-6 ml-2" />
                    <img src="@/assets/browsers/firefox.svg" alt="firefox" class="w-6 ml-2" />
                </div>
                <div class="flex mt-2">
                    IOS (iphone, ipad)
                    <img src="@/assets/browsers/safari-ios.svg" alt="safari" class="w-6 ml-2" />
                </div>
                <div class="flex mt-2">
                    Android
                    <img src="@/assets/browsers/chrome.svg" alt="chrome" class="w-6 ml-2" />
                    <!-- <img src="@/assets/browsers/firefox.svg" alt="safari" class="w-6 ml-2" /> -->
                </div>
                <div class="text-xs mt-6">Rekomenduojame naudoti naujausias naršyklių versijas</div>
            </div>
            <mainBtn
                class="mb-8 mt-8"
                @on-click="toggleOpen"
                text="Pirmyn"
                icon="camera"
                textColor="white"
                bgColor="green"
            />
            <svg
                class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                    >
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>
        </div>
    </div>
</template>

<script>
import mainBtn from "./smallComponents/mainBtn";

export default {
    components: {
        mainBtn,
    },
    methods: {
        toggleOpen() {
            this.$store.commit("openMessenger");
            this.$store.commit("setShowMessenger", true);
        },
    },
};
</script>

<style></style>
