<template>
  <div
    :id="id"
    @click="$emit('on-click', method)"
    class="bg-yellow flex opacity-0 text-green ids p-2 pl-4 pr-4 rounded mt-4 cursor-pointer shadow-lg hover:shadow-md"
  >
    <div v-html="icons[icon]" class="mr-2"></div>
    <div v-html="text"></div>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.js";

export default {
  props: {
    id: {
      type: String,
    },
    method: {
      type: String,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        question:
          '<svg width="20" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>',
        phone:
          '<svg width="20" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>',
      },
      transition: false,
    };
  },
  mounted() {},
  methods: {
    animateAppear() {
      const self = this;
      anime({
        targets: `.ids`,
        opacity: [0, 1],
        translateY: [-50, 0],
        easing: "easeOutSine",
        duration: 600,
        delay: function(el, i) {
          return i * 120;
        },
        complete: function() {
          self.transition = true;
        },
      });
    },
  },
};
</script>

<style></style>
