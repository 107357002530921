<template>
  <div
    @click.stop.prevent="$emit('close')"
    class="fixed flex items-center justify-center bg-overlay top-0 left-0 w-full h-screen z-50"
  >
    <div @click.stop.prevent class="bg-white relative rounded-3xl p-12">
      <div class="flex mx-auto items-center justify-center">
        <div>
          <div class="text-header font-bold w-full mb-4">
            Prisijungti
          </div>

          <div :key="item" v-for="item of userInfo">
            <inputTextField
              v-model="item.value"
              :isRequired="item.required"
              :isEmail="item.isEmail"
              :isPhone="item.isPhone"
              :placeholder="item.placeholder"
              :type="item.type"
              :item="item"
              v-show="!item.disabled"
            />
          </div>

          <div
            v-if="!forgotMode"
            @click="forgotPassword"
            class="mt-4 cursor-pointer text-extrasm text-green"
          >
            Pamiršau slaptažodį
          </div>

          <mainBtn
            @on-click="login"
            class="mt-8"
            :text="forgotMode ? 'Priminti' : 'Pirmyn'"
            icon="arrow"
            iconPos="right"
            textColor="green"
            bgColor="yellow"
          />
        </div>
      </div>
    </div>
    <div
      @click="$emit('close')"
      v-html="closeIcon"
      class="absolute right-6 top-6 cursor-pointer"
    ></div>
  </div>
</template>

<script>
const axios = require("axios");
import mainBtn from "./smallComponents/mainBtn";
import inputTextField from "./formComponents/inputTextField";
// import inputCheckBox from "./formComponents/inputCheckBox";

export default {
  components: {
    mainBtn,
    // inputCheckBox,
    inputTextField,
  },
  data() {
    return {
      forgotMode: false,
      closeIcon:
        '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
    };
  },
  computed: {
    userInfo() {
      return {
        email: {
          label: "El. paštas",
          value: "",
          isEmail: true,
        },
        password: {
          label: "Slaptažodis",
          value: "",
          type: "password",
          disabled: this.forgotMode,
        },
      };
    },
  },
  methods: {
    forgotPassword() {
      this.forgotMode = true;
    },
    extractCredentials() {
      return {
        email: this.userInfo.email.value,
        password: this.userInfo.password.value,
      };
    },
    async login() {
      if (this.forgotMode) {
        const result = await axios.post(
          `${this.$root.baseUrl}/api/remindEmail`,
          { email: this.userInfo.email.value }
        );
        console.log(result);
        return;
      }

      const result = await axios.post(
        `${this.$root.baseUrl}/api/login`,
        this.extractCredentials()
      );

      if (!result.data.user.is_activated) {
        this.$root.notify(
          "Paskyra gali būti aktyvuota tik patvirtinus el. pašto adresą. Aktyvavimo nuorodą rasite savo el. pašto dėžutėje",
          "warning"
        );
        return;
      }

      this.$store.commit("setUser", result.data.user);
      this.$store.commit("setUserToken", result.data.token);
      this.$root.setCookie("token", result.data.token, 3);
      this.$root.initAxiosInstance();
      this.$root.notify("Sėkmingai prisijungėte", "success");
      this.$emit("close");
    },
  },
};
</script>

<style></style>
