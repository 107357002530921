import Vue from 'vue'
import App from './App.vue'
import store from './store';
const axios = require("axios");
import { version } from "../package";

import VueNotification from '@mathieustan/vue-notification';
Vue.use(VueNotification, {
  theme: {
    colors: {
      success: '#014C40',
      error: '#C33C22',
      info: '#5d6a89'
    },
  },  
  breakpoints: {
    0: {
      bottom: true,
    },
    480: {
      bottom: true,
    },
  },
});
Vue.config.productionTip = false

var eventHub = new Vue();
global.eventHub = eventHub;
import { mapState } from "vuex";

var vm = new Vue({
  store,
  data: {
    peer: null,
    appVersion: version,
    showUserAccount: false,
    windowWidth: 0,
    axiosInstance: null,
    showPurchaseScreen: false,
    baseUrl: process.env.VUE_APP_BASE_URL
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      currentStream: (state) => state.currentStream,
      cartContent: (state) => state.cartContent,
    }),
    isMobile(){
      return this.windowWidth < 600
    },
    userID(){
      let userID = this.user
        ? this.user.id
        : null;
      return userID
    },
  },
  mounted(){
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", function() {
      this.windowWidth = window.innerWidth;
    });    
    this.initTempUser()
  },
  methods: {
    scrollToHowItWorks() {
      const offsetTop = document.querySelector("#how-it-works").offsetTop;
      scroll({
        top: offsetTop+100,
        behavior: "smooth",
      });
    },    
    initTempUser(){
      if (this.getCookie("temp_user_id")) {
        this.$store.commit('setTempUserID', this.getCookie("temp_user_id"));
        return
      }
      const tempUserID = Math.random()
          .toString(36)
          .substring(7);
      this.setCookie("temp_user_id", tempUserID, 1000) 
      this.$store.commit('setTempUserID', tempUserID);        
    },
    notify (message, type = 'success') {
      this.$notify[type]({message:message, offset:0});
    },    
    notifyAction (content, button, functionToLaunch) {
      this.$notify.info({message:content, showClose:true, closeDelay:10000, hideIcon: true, offset:10, actionText:button, onActionClick: functionToLaunch});
    },  
    refreshToken() {
      const self = this;
      axios({
        method: "post",
        url: `${this.$root.baseUrl}/api/refresh`,
        headers: {
          Authorization: "Bearer " + this.$root.getCookie("token"),
        },
        data: {
          token: this.$root.getCookie("token"),
        },
      })
        .then(function(response) {
          if (response && response.data.user && response.data.operator) {
            self.$root.setCookie("token", response.data.token, 3);
            self.$store.commit("setUser", response.data.user);
            self.$root.loggedIn = true;
            self.initAxiosInstance();
          }
        })
        .catch(function() {
        });
    },
    initAxiosInstance() {
      const self = this
      this.$root.axiosInstance = axios.create({
        baseURL: `${this.baseUrl}${this.user ? '/api/' : ''}`,
        timeout: 5000,
        headers: {
          Authorization: "Bearer " + this.getCookie("token"),
        },
      });
      this.$root.axiosInstance.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          if (error.response.data.msg == "expired") {
            self.refreshToken();
          } else if (error.response.data.msg == 'could_not_refresh_token') {
            location.reload()
          }
          return Promise.reject(error);
        }
      );
    },    
    setCookie(name, value, daysToLive) {
      var cookie = name + "=" + encodeURIComponent(value);
      if (typeof daysToLive === "number") {
        cookie += ";path=/; max-age=" + daysToLive * 24 * 60 * 60;
        document.cookie = cookie;
      }
    },
    getCookie(name) {
      var cookieArr = document.cookie.split(";");
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (name == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
        }
      }
      return null;
    },        
  },
  render: h => h(App),
}).$mount('#app')

global.vm = vm