<template>
  <div
    id="messenger-slideout"
    style="min-width:350px"
    class="fixed md:absolute bottom-0 left-0 md:left-auto h-screen pt-10 md:pt-0 md:h-auto right-0 md:bottom-20 cursor-default flex flex-col justify-between bg-lightYellow min-h-0 rounded-lg shadow-xl"
  >
    <div
      class="bg-gradient-to-t relative from-green to-analog text-white p-12 pt-20 md:pt-12 md:rounded-lg"
    >
      <div
        @click="close"
        class="absolute top-8 right-4 cursor-pointer"
        v-html="closeIcon"
      ></div>
      <div id="greeting" class="text-textMob md:text-header mb-8 md:mb-2">
        <div v-if="user">
          <div class="pb-2">Labas!</div>
          🐟🎣
        </div>
        <div v-else>Labas! 🐟🎣</div>
      </div>
      <div class="text-messengerBase"></div>
      <div
        class="absolute flex justify-center items-center mt-4 w-full -left-2 z-10"
      >
        <contentBlock class="w-4/5">
          <div v-html="status" />
          <div v-if="timePassed">laukiama: {{ timePassed }}</div>
        </contentBlock>
      </div>
    </div>
    <div
      v-if="userIsBlocked"
      class="flex h-64 w-full items-center justify-center"
    >
      <div class="text-red">
        <div class="w-full flex justify-center mb-2" v-html="blockedIcon"></div>
        <div class="w-full">Vartotojas yra užblokuotas</div>
      </div>
    </div>
    <div v-else>
      <div
        id="action-options"
        v-if="!sendMessage"
        class="p-12 pb-32 w-full sm:w-42 inline-block max-h-96 overflow-y-auto overflow-hidden"
      >
        <div id="chat" class="inline-block max-h-96 overflow-y-auto">
          <div class="w-full">
            <chatContent
              :item="item"
              :key="item.id"
              v-for="item of chatStart"
            />
          </div>
        </div>

        <actionOption
          :id="action.id"
          :key="action.id"
          @on-click="initAction"
          :method="action.method"
          v-for="action of actions"
          :text="action.text"
          :icon="action.icon"
        />
      </div>
      <div
        id="chat"
        v-else
        class="p-12 pb-6 inline-block max-h-96 overflow-y-auto"
        style="min-height:293px"
      >
        <chatContent :item="item" :key="item.id" v-for="item of chat" />
        <div
          @click.stop.prevent="closeCall"
          class="w-3/4 cursor-pointer inline-block clear-both float-right text-right"
        >
          <div
            class="flex p-4 items-center justify-around mt-4 bg-red text-white shadow-md text-center font-semibold rounded-xl text-messengerBase"
          >
            <div v-html="callIcon"></div>
            <div>Persigalvojau</div>
          </div>
          <div
            class="inline-flex relative mt-4 items-center text-indigo-500 bg-indigo-100 rounded-full  justify-items-end text-right"
          >
            <div
              v-if="waitingForOperator"
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-50 opacity-15"
            ></div>
            <span class="items-center px-4 py-2 text-xs font-medium">
              Skambiname
            </span>
            <div
              class="flex items-center justify-items-end text-right pr-4"
              v-html="phone"
            ></div>
          </div>
        </div>
      </div>
      <!-- <div v-if="sendMessage" class="relative text-messengerBase rounded-2xl">
        <input
          @keydown.enter="enter"
          style="border-top:1px solid rgba(211,211,211,0.5)"
          class="w-full h-full placeholder-black rounded-b-lg p-4 bg-white focus:border-0 focus:outline-none focus:ring-0"
          type="text"
          v-model="message"
          placeholder="Rašyti žinutę"
        />
      </div> -->
    </div>
    <div class="absolute arrow-down right-5 -bottom-3 opacity-9"></div>
    <div class="absolute bottom-0 text-messengerBase rounded-2xl p-3">
      <span class="text-gray-transp">Powered by fixels.io </span>
      <span @click="turnOnTestMode" class="text-gray-transp"
        >(v.{{ $root.appVersion }})</span
      >
    </div>
  </div>
</template>

<script>
import { db } from "./fire.js";
var DetectRTC = require("detectrtc");
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import contentBlock from "./components/contentBlock";
import chatContent from "./components/chatContent";
import actionOption from "./components/actionOption";
import { differenceInMinutes, formatDistanceToNow } from "date-fns";
const axios = require("axios");
import { mapState } from "vuex";
var Peer = require("simple-peer");
import { lt } from "date-fns/locale";

export default {
  data() {
    return {
      testModeClick: 0,
      notSupported: false,
      message: null,
      deviceID: null,
      timePassed: null,
      callStartTime: null,
      peer: null,
      blockedIcon:
        '<svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>',
      sendMessage: false,
      iceServers: null,
      placeInRow: null,
      spanshot: null,
      showShoppingCart: true,
      phone:
        '<svg width="16" height="" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>',
      callIcon:
        '<svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-off"><path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path><line x1="23" y1="1" x2="1" y2="23"></line></svg>',
      status:
        "Sveikiname apsilankius pirmoje Lietuvoje žūklės reikmenų video parduotuvėje. Pradedam?",
      actions: [
        {
          id: "initCall",
          method: "initCall",
          icon: "phone",
          text: "Pradėti video apsipirkimą"
        },
        {
          id: "howItWorks",
          method: "howItWorks",
          icon: "question",
          text: "Kaip tai veikia?"
        }
        // {
        //   id: "writeMsg",
        //   method: "writeMsg",
        //   text: "Rašyti žinutę",
        // },
        // {
        //   method: 'cart',
        //   text: 'Rodyti pirkinių krepšelį'
        // },
        // {
        //   method: 'myAccount',
        //   text: 'Mano paskyra'
        // }
      ],
      closeIcon:
        '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
      chatStart: [
        {
          id: 1,
          me: false,
          content: "Kuo galėtume jums padėti?"
        }
        // {
        //   id: 1,
        //   me: false,
        //   content: "Beje, kuo tu vardu?",
        //   time: "2 seconds ago",
        // },
      ],
      chat: [
        {
          id: 1,
          me: false,
          content: "Mūsų konsultantai tuoj atskubės į pagalbą",
          time: ""
        }
        // {
        //   id: 1,
        //   me: false,
        //   content: "Beje, kuo tu vardu?",
        //   time: "2 seconds ago",
        // },
      ]
    };
  },
  components: {
    contentBlock,
    chatContent,
    actionOption
  },
  mounted() {
    this.getTotalWaiting();
    this.getIceServers();
    this.getCurrentDeviceID();
    this.clearStatus();
    this.checkIfAvailable();
    setTimeout(() => {
      this.checkIfBlocked();
    }, 1000);
  },
  computed: {
    ...mapState({
      user: state => state.user,
      userID: state => state.userID,
      minimized: state => state.minimized,
      idleStatusInterval: state => state.idleStatusInterval,
      showMessenger: state => state.showMessenger,
      currentCallAnswerKey: state => state.currentCallAnswerKey,
      userIsBlocked: state => state.userIsBlocked,
      videoCallWindowShow: state => state.videoCallWindowShow,
      messengerOpen: state => state.messengerOpen,
      operator: state => state.operator,
      waitingForOperator: state => state.waitingForOperator,
      tempUserID: state => state.tempUserID,
      cartContent: state => state.cartContent,
      currentStream: state => state.currentStream,
      currentCallKey: state => state.currentCallKey
    }),
    statuses() {
      return {
        initial:
          "Sveikiname apsilankius pirmoje Lietuvoje žūklės video parduotuvėje. Pradedam?",
        leaveMessage: "Palik mums žinutę ir savo el. pašto adresą arba tel",
        waitingForCall: `Laukiama konsultanto... <br />Jūs esate<span style="color:red"> ${
          this.placeInRow ? this.placeInRow : "Pirmas"
        } eilėje.</span>`
      };
    }
  },
  beforeDestroy() {
    clearInterval(this.timeAgoWaitInterval);
  },
  methods: {
    turnOnTestMode() {
      if (this.testModeClick === 5) {
        this.$store.commit("triggerTestMode");
        this.testModeClick = 0;
        return;
      }
      this.testModeClick++;
    },
    checkIfAvailable() {
      const self = this;
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log("This browser does not support the API yet");
        return false;
      }

      DetectRTC.load(function() {
        if (DetectRTC.isWebRTCSupported === false) {
          self.notSupported = true;
          self.$root.notify(
            "Jūsų naršyklė nepalaiko video skambučių. Rekomenduojame naudoti naujas Chrome, Safari arba Firefox naršykles",
            "error"
          );
          return false;
        }

        if (DetectRTC.hasWebcam === false) {
          self.notSupported = true;
          self.$root.notify(
            "Nerandome įdiegtos internetinės kameros.",
            "error"
          );
          return false;
        }

        if (DetectRTC.hasMicrophone === false) {
          self.notSupported = true;
          self.$root.notify("Jūsų įtaisas neturi įdiegto mikrofono", "error");
          return false;
        }
        if (
          DetectRTC.hasSpeakers === false &&
          (DetectRTC.browser.name === "Chrome" ||
            DetectRTC.browser.name === "Edge")
        ) {
          self.notSupported = true;
          self.$root.notify(
            "Jūsų sistema neturi galimybės groti video/audio",
            "error"
          );
          return false;
        }
      });
      return true;
    },

    formatTimeAgo() {
      if (!this.callStartTime) return;
      this.timePassed = formatDistanceToNow(
        new Date(parseInt(this.callStartTime)),
        {
          includeSeconds: true,
          locale: lt
        }
      );
    },

    getTimeAgo() {
      return differenceInMinutes(
        Date.now(),
        new Date(parseInt(this.call.timestamp))
      );
    },

    howItWorks() {
      this.$root.scrollToHowItWorks();
      this.$emit("close");
    },

    clearStatus() {
      db.ref(`userCart/${this.userID}/status`).remove();
    },
    async getCurrentDeviceID() {
      // We recommend to call `load` at application startup.
      const fp = await FingerprintJS.load();

      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      const result = await fp.get();

      // This is the visitor identifier:
      this.deviceID = result.visitorId;
    },

    writeMsg() {
      this.sendMessage = true;
    },

    checkIfBlocked() {
      const self = this;
      db.ref(`blockedUsers`)
        .orderByChild("deviceID")
        .equalTo(this.deviceID)
        .on("value", function(snapshot) {
          if (snapshot.val()) {
            self.$store.commit("setUserBlocked", true);
          }
        });
    },

    clearDiscount() {
      db.ref(`userCart/${this.userID}/discount`).remove();
    },

    initCall() {
      if (this.notSupported) {
        this.$root.notify(
          "Video apsipirkimas negalimas. Dažniausios šios klaidos priežastys: sena naršyklė, mikrofono arba kameros nebuvimas arba neteisinga konfigūracija",
          "error"
        );
        return;
      }
      //   if (this.checkIfAvailable()) {}
      this.status = this.statuses["waitingForCall"];
      this.connectWebrtc();
    },

    playCallSound() {
      const callSound = new Audio("/assets/notification.mp3");
      setTimeout(() => {
        callSound.play();
      }, 1000);
      setTimeout(() => {
        callSound.play();
      }, 6000);
    },

    registerCallTime() {
      this.callStartTime = Date.now();
    },

    askForName() {},

    getTotalWaiting() {
      const self = this;
      db.ref(`signaling`).once("value", function(snapshot) {
        self.placeInRow = snapshot.numChildren();
      });
    },

    initAction(method) {
      this[method]();
    },

    initIdleStatus() {
      this.$store.commit(
        "setIdleStatusInterval",
        setInterval(() => {
          db.ref(`signaling/${this.currentCallAnswerKey}/activeTime`).set(
            Date.now()
          );
        }, 10000)
      );
    },

    waitForSignalAnswer() {
      const self = this;
      this.$store.commit("setWaitingForOperator", true);
      db.ref(`signaling/${this.currentCallAnswerKey}`).on("value", function(
        snapshot
      ) {
        if (!snapshot.val()) return;
        if (snapshot.val().message) {
          self.$root.notify(
            "Šiuo metu visi operatoriai užimti. Badykite kiek vėliau",
            "error"
          );
          self.closeCall();
          self.status = self.statuses["initial"];
        }
        if (snapshot.val().currentCallKey) {
          self.$store.commit(
            "setCurrentCallKey",
            snapshot.val().currentCallKey
          );
        }

        if (snapshot.val().operator) {
          self.currentOperator = snapshot.val().operator;
          self.$store.commit("setCurrOperator", self.currentOperator);
        }
        if (snapshot.val().signal_answer) {
          self.peer.signal(snapshot.val().signal_answer);
        }
      });
    },

    sendSignalToDb(signal) {
      const timestamp = JSON.stringify(Date.now());
      let user;
      let loggedIn;
      if (this.user) {
        loggedIn = true;
        user = this.user;
      } else {
        loggedIn = false;
        user = this.tempUserID;
        this.$store.commit("setUserID", user);
      }
      const result = db.ref(`signaling`).push({
        deviceID: this.deviceID,
        loggedIn: loggedIn,
        user: user,
        signal_offer: signal,
        timestamp: timestamp,
        mobile: this.$root.isMobile
      });
      this.$store.commit("setCurrentCallAnswerKey", result.key);
      setTimeout(() => {
        this.waitForSignalAnswer();
      }, 2000);
    },

    connectWebrtc() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { ideal: 1200 },
            height: { ideal: 800 },
            facingMode: "user" // environment, user
          },
          audio: true
        })
        .then(this.gotMedia)
        .catch(() => {
          this.$root.notify(
            "Įvyko klaida tikrinant naršyklės video/audio galimybes ",
            "error"
          );
        });
    },

    async getIceServers() {
      const result = await axios.get(`${this.$root.baseUrl}/getIceServers`);
      this.iceServers = result.data.iceServers;
    },

    callStarted() {
      this.sendMessage = true;
      this.waitForSignalAnswer();
      this.askForName();
      this.registerCallTime();
      this.initIdleStatus();
      this.clearStatus();
      this.clearDiscount();
      this.timeAgoWaitInterval = setInterval(() => {
        this.formatTimeAgo();
      }, 10000);
      this.playCallSound();
    },

    async gotMedia(stream) {
      const self = this;
      this.callStarted();
      const iceServers = [
        {
          urls: "stun:stun2.kotas.lt:3478"
        },
        {
          urls: "stun:stun.l.google.com:19302"
        },
        {
          urls: "turn:turn2.kotas.lt:3478",
          username: "kotas",
          credential: "webrtc"
        }
      ];

      this.$root.peer = this.peer = new Peer({
        initiator: true,
        config: {
          iceServers
        },
        stream: stream,
        trickle: false
      });

      this.$store.commit("setCurrentStream", stream);

      this.peer.on("error", err => console.log("error", err));

      this.peer.on("signal", data => {
        self.sendSignalToDb(JSON.stringify(data));
      });

      this.peer.on("stream", stream => {
        if (self.messengerOpen) {
          self.$store.commit("openMessenger");
        }
        self.$store.commit("setWaitingForOperator", false);
        self.$store.commit("setVideoCallWindowShow", true);
        clearInterval(self.idleStatusInterval);
        setTimeout(() => {
          const video = document.querySelector("#operator-video-stream");
          if ("srcObject" in video) {
            video.srcObject = stream;
          } else {
            video.src = window.URL.createObjectURL(stream); // for older browsers
          }
          video.play();
          self.initMyVideo();
        }, 1000);
      });

      this.peer.on("connect", () => {
        self.peer.send("whatever" + Math.random());
      });

      this.peer.on("data", data => {
        console.log("data", data);
      });

      this.peer.on("close", () => {
        self.pauseAllVideo();
        clearInterval(self.idleStatusInterval);
        setTimeout(() => {
          self.currentStream.getTracks().forEach(track => track.stop());
          self.$root.peer.destroy();
          self.$store.commit("closeMessenger");
          self.$store.commit("setVideoCallWindowShow", false);
          self.$store.commit("setShowMessenger", false);
          db.ref(`userCart/${this.userID}/finished`).once("value", function(
            snapshot
          ) {
            if (snapshot.val() && snapshot.val().time) {
              self.$root.notify(
                "Video pirkimas sėkmingai baigtas. Galite tęsti apmokant krepšelį",
                "success"
              );
              if (self.$route.name !== "atsiskaityti") {
                self.$router.push("/atsiskaityti").catch(() => {});
              }
              self.$emit("close");
            } else {
              self.$root.notify("Video ryšys buvo nutrauktas", "error");
            }
          });
        }, 300);
      });
    },

    pauseAllVideo() {
      const video = document.querySelector("#my-stream-video");
      if (video) {
        video.pause();
      }
      const videoOperator = document.querySelector("#operator-video-stream");
      if (videoOperator) {
        videoOperator.pause();
      }
    },

    closeCall() {
      this.status = this.statuses["initial"];
      this.currentStream.getTracks().forEach(track => track.stop());
      this.$root.peer.destroy();
      clearInterval(this.idleStatusInterval);
      setTimeout(() => {
        db.ref(`signaling/${this.currentCallAnswerKey}`).remove();
        this.$store.commit("setShowMessenger", false);
      }, 1000);
      this.$store.commit("setVideoCallWindowShow", false);
      this.$store.commit("setWaitingForOperator", false);
    },

    close() {
      this.$store.commit("openMessenger");
    },

    initMyVideo() {
      const video = document.querySelector("#my-stream-video");
      video.srcObject = this.peer.streams[0];
      this.peer.streams[0].getTracks()[1].enabled = true;
      video.play();
    },

    enter() {
      this.chat.push({
        id: 3,
        me: true,
        content: this.message,
        time: "some time"
      });
      this.message = null;
      const element = document.querySelector("#chat");
      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
      }, 10);
    }
  }
};
</script>

<style>
.arrow-down {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fef6e0;
}
</style>
