<template>
    <div id="app" :class="getClasses">
        <loginModal @close="$store.commit('toggleShowLoginModal', false)" v-if="showLoginModal" />
        <Slideout
            v-show="(videoCallWindowShow ? minimized : true) || !isMobile"
            menu="#menu"
            panel="#panel"
            side="right"
            padding="356"
            :toggleSelectors="['#some', '#close-cart', '#close-inner']"
            @on-open="open"
            @on-close="close"
        >
            <!-- <mobMenu /> -->
            <nav id="menu">
                <shoppingCart />
            </nav>
            <main id="panel">
                <slideInModal v-if="showPrivacyOrReturns" />

                <div v-html="bg" style="z-index:-1" class="absolute top-0 left-0 z-auto overflow-hidden"></div>
                <mainMenu @user-account="$root.showUserAccount = true" @login="openLoginModal" />
                <div>
                    <router-view />
                </div>
                <howItWorks id="how-it-works" />
                <pageFooter />
            </main>
        </Slideout>
        <videoMessenger />
        <div v-if="testMode" class="fixed top-2 right-2 z-50">
            <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow text-red">
                Test mode
            </span>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
const axios = require("axios");
import { version } from "../package";
import Slideout from "vue-slideout";
import mainMenu from "./components/mainMenu";
import slideInModal from "./components/slideInModal";
import loginModal from "./components/loginModal";
import videoMessenger from "./components/videoMessenger/initState";
import pageFooter from "./components/homepage/pageFooter";
import howItWorks from "./components/howItWorks";
import shoppingCart from "./components/shoppingCart";
import VueRouter from "vue-router";
import routes from "./routes.js";
import { mapState } from "vuex";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes,
    linkActiveClass: "active",
});

export default {
    router,
    name: "App",
    data() {
        return {
            appVersion: version,
            bg:
                '<svg width="1440" height="1147" viewBox="0 0 1440 1147" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="-1033" y="-320.598" width="2075.22" height="2075.22" rx="300" transform="rotate(-45 -1033 -320.598)" fill="#F9D77A" fill-opacity="0.23"/> </svg>',
        };
    },
    components: {
        mainMenu,
        pageFooter,
        shoppingCart,
        Slideout,
        videoMessenger,
        howItWorks,
        slideInModal,
        loginModal,
    },
    mounted() {
        this.checkUser();
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            showTour: (state) => state.showTour,
            testMode: (state) => state.testMode,
            showPrivacyOrReturns: (state) => state.showPrivacyOrReturns,
            showRegModal: (state) => state.showRegModal,
            showLoginModal: (state) => state.showLoginModal,
            minimized: (state) => state.minimized,
            slideOutOpen: (state) => state.slideOutOpen,
            slideOutToggle: (state) => state.slideOutToggle,
            videoCallWindowShow: (state) => state.videoCallWindowShow,
            token: (state) => state.token,
        }),
        getClasses() {
            if (this.showPrivacyOrReturns || this.showTour || (this.videoCallWindowShow && !this.minimized)) {
                return "overflow-hidden h-screen";
            }
            return null;
        },
    },
    methods: {
        triggerShowPrivacyOrReturns(page) {
            this.$store.commit("triggerShowPrivacyOrReturns", page);
        },
        open() {
            this.$store.commit("setSlideOutOpen", true);
        },
        closeSlideOut() {
            this.$children[0].slideout.toggle();
            this.$store.commit("setSlideOutOpen", false);
        },
        checkUser() {
            const self = this;
            if (this.$root.getCookie("token")) {
                axios({
                    method: "get",
                    url: `${this.$root.baseUrl}/api/get-user`,
                    headers: {
                        Authorization: "Bearer " + this.$root.getCookie("token"),
                    },
                })
                    .then(function(response) {
                        self.$store.commit("setUser", response.data.user);
                        self.$root.initAxiosInstance();
                    })
                    .catch(function(error) {
                        if (error.response.data.msg == "expired") {
                            self.$root.refreshToken();
                        }
                    });
            } else {
                self.$root.initAxiosInstance();
            }
        },
        close() {
            this.$store.commit("setSlideOutOpen", false);
        },
        openLoginModal() {
            this.$store.commit("toggleShowLoginModal", true);
        },
    },
    watch: {
        $route() {
            window.scrollTo(0, 0);
            if (this.slideOutOpen) {
                this.closeSlideOut();
            }
        },
        slideOutToggle() {
            this.$children[0].slideout.toggle();
        },
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap");

#app {
    font-family: "Quicksand", sans-serif;
    overflow: hidden;
}

@media only screen and (max-width: 800px) {
    html,
    body {
        background: #000;
    }
}

.notification {
    z-index: 2147483647 !important;
}

.slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 356px;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
    background-color: #fef6e0;
}

.slideout-menu-left {
    left: 0;
}

.slideout-menu-right {
    right: 0;
}

.slideout-panel {
    background-color: #fff;
    position: relative;
    z-index: 1;
    will-change: transform;
    min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
    box-shadow: 0px 0px 10px rgb(122 122 122 / 30%);
}

.slideout-open .slideout-menu {
    display: block;
}
.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        background: black;
        color: white;
        border-radius: 16px;
        font-size: 13px;
        padding: 5px 10px 4px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
        z-index: 1;
    }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.popover {
        $color: #f9f9f9;

        .popover-inner {
            background: $color;
            color: black;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, 0.1);
        }

        .popover-arrow {
            border-color: $color;
        }
    }

    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}
</style>
