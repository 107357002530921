<template>
  <div class="bg-white p-4 shadow-sm rounded-xl text-black text-messengerBase">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
    },
  },
};
</script>

<style></style>
