var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed flex items-center justify-center left-0 z-30",class:{
    'bottom-0 h-8 w-full lg:h-64 lg:w-96 lg:left-10': _vm.minimized,
    'top-0 w-full h-screen bg-overlay': !_vm.minimized,
  }},[_c('div',{staticClass:"video-window relative lg:rounded-xl w-full md:h-full mx-auto",class:{
      'h-full minimized': _vm.minimized,
      'h-screen not-minimized bg-blackBg': !_vm.minimized,
    },attrs:{"id":"call-window"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.minimized),expression:"minimized"}],staticClass:"minimize absolute -top-10 left-2 lg:-left-6 lg:-top-6 z-50"},[_c('menuButton',{staticClass:"mr-4",attrs:{"icon":_vm.minimized ? 'maximizeIcon' : 'minimizeIcon',"mobile":""},on:{"on-click":_vm.minimize}}),_c('menuButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.minimized),expression:"minimized"}],staticClass:"mr-4 mt-2",attrs:{"icon":_vm.videoHidden ? 'eye' : 'eyeOff',"mobile":""},on:{"on-click":_vm.toggleHideVideo}})],1),(_vm.isScanning)?_c('div',{staticClass:"barcode-scanning-mode rounded-xl absolute top-0 left-0 w-full h-full flex items-center justify-center z-1 bg-lightYellow "},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute w-full top-10 left-0 h-2 bg-red",attrs:{"id":"red-line"}}),_c('img',{class:{ 'w-80': !_vm.minimized, 'w-40': _vm.minimized },attrs:{"src":require("@/assets/barcode.png"),"alt":""}})])]):_vm._e(),(!_vm.minimized)?_c('div',{staticClass:"absolute top-2 -right-2 lg:-top-4 lg:-right-8 z-50"},[_c('div',{staticClass:"reative"},[_c('menuButton',{staticClass:"mr-4",attrs:{"status":_vm.getCartItemsNumber,"icon":"cart","mobile":""},on:{"on-click":_vm.showCart}})],1)]):_vm._e(),(_vm.operator && !_vm.minimized)?_c('div',{staticClass:"absolute p-2 mt-16 text-extrasm cursor-pointer inline-block rounded-md text-white m-8 z-50"},[_vm._v(" Konsultantas: "+_vm._s(_vm.operator.name)+" ")]):_vm._e(),_c('video',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isScanning && !_vm.videoHidden),expression:"!isScanning && !videoHidden"}],staticClass:"left-0 rounded-xl",class:{
        'fixed bottom-8 left-4 w-40': _vm.minimized,
        'relative h-full top-0': !_vm.minimized,
      },attrs:{"id":"operator-video-stream","width":"100%","height":"636px","playsinline":"","autoplay":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showActionIcons),expression:"showActionIcons"}],staticClass:"action-icons flex absolute right-8 bottom-20 md:bottom-8",class:{ hidden: _vm.minimized || _vm.iconsHidden }},_vm._l((_vm.actions),function(action){return _c('actionIcon',{key:action,attrs:{"icon":action.icon,"tooltip":action.tooltip,"color":action.color},on:{"on-click":function($event){return _vm.initAction(action.action)}}})}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cameraOn && !_vm.isScanning),expression:"cameraOn && !isScanning"}],staticClass:"shadow-xl w-32 lg:w-48 absolute top-32 lg:bottom-10 left-4 lg:left-10 rounded-md",class:{ hidden: _vm.minimized }},[_c('video',{staticClass:"rounded-md",attrs:{"id":"my-stream-video","autoplay":"","muted":"","playsinline":""},domProps:{"muted":true}})])]),(!_vm.minimized)?_c('div',{staticClass:"absolute right-6 top-6 cursor-pointer",domProps:{"innerHTML":_vm._s(_vm.closeIcon)},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_c('slideout-panel')],1)}
var staticRenderFns = []

export { render, staticRenderFns }