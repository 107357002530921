<template>
  <div id="kontaktai" class="container mx-auto w-9/12 pb-8">
    <a
      target="_blank"
      href="https://www.google.com/maps/place/Kotas/@54.6946846,25.2910229,18.08z/data=!4m5!3m4!1s0x46dd941c4c3d5e53:0x2cb34e5eb876559f!8m2!3d54.6950848!4d25.2923036"
    >
      <img
        class="mt-24 mb-12 cursor-pointer hidden lg:block"
        src="/assets/map.png"
      />
      <img
        class="mt-24 mb-12 cursor-pointer lg:hidden w-11/12 mx-auto"
        src="/assets/map_mobile.png"
      />
    </a>
    <div
      class="lg:flex w-4/5 lg:w-3/4 mx-auto justify-between mb-8 lg:mb-24 text-gray"
    >
      <div>
        Rekvizitai:<br />
        UAB "Kotas"<br />
        įmonės kodas: 302343431<br />
        PVM kodas: LT100004763815<br />
        Rinktinės g. 45-29<br />
        LT-09206 Vilnius
      </div>
      <div class="cursor-pointer">
        Dirbame kasdien <b>nuo 6 iki 20 val</b><br />
        Šeimyniškių g. 21, Vilnius <br />
        <a href="mailto:parduotuve@kotas.lt">parduotuve@kotas.lt</a><br />
        <a href="tel:+37068650034">+37068650034</a>
      </div>
      <div>
        <div @click="openPage('privacy')" class="cursor-pointer mt-2 lg:mt-0">
          Privatumo politika
        </div>
        <div @click="openPage('return')" class="cursor-pointer mt-2">
          Pristatymo ir Grąžinimo <br />
          informacija
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    openPage(page) {
      this.$store.commit("triggerShowPrivacyOrReturns", page);
    }
  }
};
</script>

<style></style>
