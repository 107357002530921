<template>
  <div
    :class="{
      'float-right text-right': item.me,
      'float-left': !item.me,
    }"
    class="w-3/4 inline-block clear-both"
  >
    <div
      :class="{
        'bg-yellow': item.me,
        'bg-white': !item.me,
      }"
      class="p-4 mt-4 shadow-md text-left rounded-xl text-black text-messengerBase"
    >
      <div v-html="item.content"></div>
    </div>
    <div style="font-size:10px" class="text-gray mt-2">{{ item.time }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: String,
    },
  },
};
</script>

<style></style>
