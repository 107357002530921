<template>
  <div
    @mouseover="$emit('set-active', true)"
    @mouseleave="$emit('set-active', false)"
    @click="$emit('on-click')"
    class="relative select-none justify-evenly cursor-pointer items-center hover:bg-yellow-400 transition flex pl-6 pt-3 pb-2.5 h-12 left-0 top-0 bg-white shadow rounded-lg"
    :class="{
      'bg-green text-white': active,
      'w-40 pr-24': !mobile,
      'w-14 text-center pr-5': mobile,
    }"
    :style="{ width: !mobile ? '169px' : null }"
    style="height: 50px;"
  >
    <div id="button-icon" v-html="icons(icon)"></div>
    <p
      v-if="!mobile"
      :class="{ 'font-bold': bold }"
      class="w-28 h-6 pl-4 absolute flex items-center inset-y-0 right-0 my-auto text-base leading-normal text-green-900"
    >
      {{ text }}
    </p>
    <div
      v-if="status"
      class="absolute -inset-y-1 -right-2 cursor-pointer w-6 h-6 rounded-full text-center bg-yellow text-green"
    >
      <span class="text-green font-bold">{{ status }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
    mobile: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    iconColor() {
      return this.active ? "#FFF" : "#014C40";
    },
  },
  mounted() {},
  methods: {
    icons(icon) {
      const icons = {
        eye: {
          icon:
            '<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" /> </svg>',
        },
        eyeOff: {
          icon:
            '<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" /> </svg>',
        },
        minimizeIcon: {
          icon:
            '<svg class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minimize-2"><polyline points="4 14 10 14 10 20"></polyline><polyline points="20 10 14 10 14 4"></polyline><line x1="14" y1="10" x2="21" y2="3"></line><line x1="3" y1="21" x2="10" y2="14"></line></svg>',
        },
        maximizeIcon: {
          icon:
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize-2"><polyline points="15 3 21 3 21 9"></polyline><polyline points="9 21 3 21 3 15"></polyline><line x1="21" y1="3" x2="14" y2="10"></line><line x1="3" y1="21" x2="10" y2="14"></line></svg>',
        },
        hamburger: {
          icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5 16H27" stroke="#014C40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M5 8H27" stroke="#014C40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M5 24H27" stroke="#014C40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> `,
        },
        person: {
          icon: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13 16.25C16.5899 16.25 19.5 13.3399 19.5 9.75C19.5 6.16015 16.5899 3.25 13 3.25C9.41015 3.25 6.5 6.16015 6.5 9.75C6.5 13.3399 9.41015 16.25 13 16.25Z" stroke="${this.iconColor}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3.14734 21.9365C4.14627 20.2075 5.58262 18.7718 7.3121 17.7736C9.04157 16.7754 11.0033 16.25 13.0001 16.25C14.997 16.25 16.9587 16.7755 18.6881 17.7737C20.4176 18.7719 21.8539 20.2077 22.8528 21.9368" stroke="${this.iconColor}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`,
        },
        cart: {
          icon: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.5 18.6875H6.9565C6.76622 18.6875 6.58198 18.6207 6.43589 18.4988C6.28981 18.3769 6.19114 18.2076 6.1571 18.0203L3.44516 3.10466C3.41113 2.91745 3.31246 2.74812 3.16638 2.6262C3.02029 2.50428 2.83605 2.4375 2.64577 2.4375H0.8125" stroke=${this.iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M7.3125 22.75C8.43433 22.75 9.34375 21.8406 9.34375 20.7188C9.34375 19.5969 8.43433 18.6875 7.3125 18.6875C6.19067 18.6875 5.28125 19.5969 5.28125 20.7188C5.28125 21.8406 6.19067 22.75 7.3125 22.75Z" stroke=${this.iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.5 22.75C20.6218 22.75 21.5312 21.8406 21.5312 20.7188C21.5312 19.5969 20.6218 18.6875 19.5 18.6875C18.3782 18.6875 17.4688 19.5969 17.4688 20.7188C17.4688 21.8406 18.3782 22.75 19.5 22.75Z" stroke=${this.iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M4.0625 6.5H21.7765C21.8955 6.5 22.013 6.52614 22.1208 6.57658C22.2286 6.62702 22.324 6.70052 22.4003 6.79189C22.4765 6.88326 22.5318 6.99027 22.5621 7.10534C22.5924 7.22042 22.5971 7.34075 22.5759 7.45784L21.2463 14.7703C21.2123 14.9576 21.1136 15.1269 20.9675 15.2488C20.8214 15.3707 20.6372 15.4375 20.4469 15.4375H5.6875" stroke=${this.iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`,
        },
        closeIcon: {
          icon:
            '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
        },
      };
      return icons[icon].icon;
    },
  },
};
</script>

<style></style>
