/* webpackPrefetch: true */
const indexPage = () =>
  import(/* webpackChunkName: "indexPage" */ "./indexPage");    
const aboutUs = () =>
  import(/* webpackChunkName: "aboutUs" */ "./aboutUs");   
const frontCart = () =>
import(/* webpackChunkName: "frontCart" */ "./frontCart");  
const frontCheckout= () =>
  import(/* webpackChunkName: "frontCheckout" */ "./frontCheckout"); 
const frontUserAccount= () =>
  import(/* webpackChunkName: "userAccount" */ "./frontUserAccount");
const successPurchase= () =>
  import(/* webpackChunkName: "successPurchase" */ "./components/successPurchase");
const failedPurchase= () =>
  import(/* webpackChunkName: "failedPurchase" */ "./components/failedPurchase");  
const activate = () =>
  import(/* webpackChunkName: "purchases" */ "./activate"); 
  
const routes = [
  {
    path: "/",
    name: "index",
    component: indexPage,
  }, 
  {
    path: "/apie-mus",
    name: "about-us",
    component: aboutUs,
  },
  {
    path: "/krepselis",
    name: "krepselis",
    component: frontCart,
  },
  {
    path: "/paskyra",
    name: "paskyra",
    component: frontUserAccount,
  },
  {
    path: "/atsiskaityti",
    name: "atsiskaityti",
    component: frontCheckout,
  }, 
  {
    path: "/sekmingas-atsiskaitymas",
    name: "sekmingas",
    component: successPurchase,
  },  
  {
    path: "/apmokejimas-atsauktas",
    name: "sekmingas",
    component: failedPurchase,
  },   
  {
    path: "/aktyvuoti/:code",
    name: "aktyvuoti",
    component: activate,
  }, 
              
];
export default routes;
