<template>
  <div
    :id="`main-btn-${icon}`"
    @click="
      disabled ? $root.notify('Veiksmas negalimas', 'info') : $emit('on-click')
    "
    class="inline-flex p-3 pl-8 pr-8 text-center shadow-md rounded-lg cursor-pointer select-none hover:shadow-lg transition"
    :class="`bg-${bgColor} text-${textColor} ${disabled ? 'disabled' : ''}`"
  >
    <div
      :style="{ 'flex-direction': iconPos == 'right' ? 'row-reverse' : 'null' }"
      class="flex items-center justify-between"
    >
      <div class="transition" id="btn-icon" v-html="icons[icon]"></div>
      <span
        :class="{
          'pr-2': iconPos == 'right',
          'pl-2': iconPos == 'right' || null,
          'font-bold': bold,
        }"
        class="text-base leading-normal pl-2"
      >
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    iconPos: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    bold: {
      type: Boolean,
    },
    textColor: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        logout:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>',
        save:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>',
        arrow:
          '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.0625 13H21.9375" stroke="#014C40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14.625 5.6875L21.9375 13L14.625 20.3125" stroke="#014C40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
        question:
          '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.0002 22.75C18.385 22.75 22.7502 18.3848 22.7502 13C22.7502 7.61522 18.385 3.25 13.0002 3.25C7.61547 3.25 3.25024 7.61522 3.25024 13C3.25024 18.3848 7.61547 22.75 13.0002 22.75Z" stroke="#014C40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.0002 15.4375V14.625C13.643 14.625 14.2714 14.4344 14.8058 14.0773C15.3403 13.7202 15.7569 13.2126 16.0029 12.6187C16.2488 12.0249 16.3132 11.3714 16.1878 10.741C16.0624 10.1105 15.7529 9.53143 15.2983 9.0769C14.8438 8.62238 14.2647 8.31285 13.6343 8.18745C13.0038 8.06205 12.3504 8.12641 11.7565 8.37239C11.1627 8.61838 10.6551 9.03494 10.298 9.5694C9.94085 10.1039 9.75024 10.7322 9.75024 11.375" stroke="#014C40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13 19.7031C13.5609 19.7031 14.0156 19.2484 14.0156 18.6875C14.0156 18.1266 13.5609 17.6719 13 17.6719C12.4391 17.6719 11.9844 18.1266 11.9844 18.6875C11.9844 19.2484 12.4391 19.7031 13 19.7031Z" fill="#014C40"/> </svg> ',
        camera:
          '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.4375 6.09375H15.4375C16.2995 6.09375 17.1261 6.43616 17.7356 7.04565C18.3451 7.65515 18.6875 8.4818 18.6875 9.34375V19.0938C18.6875 19.3092 18.6019 19.5159 18.4495 19.6683C18.2972 19.8206 18.0905 19.9062 17.875 19.9062H4.875C4.01305 19.9062 3.1864 19.5638 2.5769 18.9543C1.96741 18.3449 1.625 17.5182 1.625 16.6562V6.90625C1.625 6.69076 1.7106 6.4841 1.86298 6.33173C2.01535 6.17935 2.22201 6.09375 2.4375 6.09375V6.09375Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18.6875 11.375L24.375 8.125V17.875L18.6875 14.625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
        close:
          '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
      },
    };
  },
};
</script>

<style lang="scss">
.disabled {
  background-color: #ccc !important;
  color: #999 !important;
  cursor: not-allowed !important;
  svg path {
    stroke: #999;
  }
}
#main-btn-arrow {
  transition: 0.3s all ease;
  &:hover {
    #btn-icon {
      transform: translateX(10px);
    }
  }
}
</style>
